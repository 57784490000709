import { DownArrowIcon } from '@/assets/svgExports/DownArrowIcon';
import { RightArrowIconTransparent } from '@/assets/svgExports/RightArrowIconTransparent';
import Dropdown from '../../../CommonComponents/Dropdown/Dropdown';
import { getDescription } from '../../utils/commonGetterFunction';
import { FooterDesktopContainer, FooterMobileContainer } from '../Footer3.styled';
import { useEffect, useRef, useState } from 'react';
import { btnActionHandler } from '../../../utils/btnUtils';
import Footer2 from '../../Footer2/Footer2';
import Footer1 from '../../Footer1/Footer1';

const FooterDesktop = (props) => {
  const { footerState, store, propStyle, headerLinks } = props;
  const [dropDownActive, setDropDownActive] = useState({
    openDropDown: false,
    activeLink: '',
  });
  const dropdownRef = useRef(null);
  const parentLinkRef = useRef(null);
  const handleNavInteraction = (data) => {
    if (data.actions.type === 5) {
      if (data.actions.handler === '') {
        setDropDownActive((prevState) =>
          prevState?.activeLink === data?.id
            ? {
                openDropDown: false,
                activeLink: '',
              }
            : {
                openDropDown: true,
                activeLink: data?.id,
              }
        );
      }
    } else {
      btnActionHandler(data?.actions);
    }
  };
  const handleClickOutside = (event) => {
    if (
      parentLinkRef.current &&
      !parentLinkRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropDownActive({ openDropDown: false, activeLink: '' });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const getHandler = (data) => handleNavInteraction(data);
  const getNavLinksDesktop = (navLinkData) => {
    return navLinkData?.map((nav) =>
      nav?.name?.length > 0 ? (
        <div
          key={nav?.id}
          className="nav-links"
          onClick={() => getHandler(nav)}
          ref={parentLinkRef}
        >
          {nav?.name}{' '}
          {nav?.actions?.type === 5 && nav?.children?.length > 0 && (
            <DownArrowIcon color="black" />
          )}{' '}
          {nav?.actions?.type === 5 &&
            nav?.children?.length > 0 &&
            dropDownActive?.activeLink === nav?.id && (
              <Dropdown
                ref={dropdownRef}
                dropDownData={nav?.children}
                handler={getHandler}
                width="20px"
                height="20px"
                themeConfig={propStyle}
              />
            )}
        </div>
      ) : null
    );
  };
  const getNavLinksMobile = (navLinkData) => {
    return navLinkData?.map((nav) =>
      nav?.name?.length > 0 ? (
        <div
          key={nav?.id}
          className="nav-links"
          onClick={() => getHandler(nav)}
          ref={parentLinkRef}
        >
          <div className="links">
            {' '}
            {nav?.name}{' '}
            {nav?.actions?.type === 5 &&
              nav?.children?.length > 0 &&
              (dropDownActive?.activeLink === nav?.id ? (
                <DownArrowIcon />
              ) : (
                <RightArrowIconTransparent color="black" />
              ))}{' '}
          </div>
          {nav?.actions?.type === 5 &&
            nav?.children?.length > 0 &&
            dropDownActive?.activeLink === nav?.id && (
              <Dropdown
                ref={dropdownRef}
                dropDownData={nav?.children}
                handler={getHandler}
                width="20px"
                height="20px"
                themeConfig={propStyle}
              />
            )}
        </div>
      ) : null
    );
  };
  const [IS_DESKTOP, setIS_DESKTOP] = useState(true);

  const checkWindowSize = () => {
    if (typeof window !== 'undefined') {
      const isMobile = window.innerWidth < 768; // You can adjust this threshold
      setIS_DESKTOP(!isMobile);
    }
  };

  useEffect(() => {
    // Check window size on component mount
    checkWindowSize();

    // Add event listener for window resize only on the client side
    const handleResize = () => {
      checkWindowSize();
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  return IS_DESKTOP ? (
    <FooterDesktopContainer
      themeBackground={propStyle}
      className="modernFooter"
      propsBackground={footerState?.media?.background}
    >
      <div className={`top-section bg_type_${footerState?.media?.background?.type}`}>
        <div className="top-first-section">
          <p>Quick Links</p>
          <div className="nav-links-container">{getNavLinksDesktop(headerLinks)}</div>
        </div>
        {getDescription(footerState)}
      </div>
      <hr />
      <div className="bottom-section">
        <Footer2
          footerState={footerState}
          store={store}
          propStyle={propStyle}
          isAlreadyTransformed={true}
        />
      </div>
    </FooterDesktopContainer>
  ) : (
    <FooterMobileContainer
      themeBackground={propStyle}
      className="modernFooter"
      propsBackground={footerState?.media?.background}
    >
      <div className={`top-section bg_type_${footerState?.media?.background?.type}`}>
        <div className="top-first-section">
          <p>Quick Links</p>
          <div className="nav-links-container">{getNavLinksMobile(headerLinks)}</div>
        </div>
        {getDescription(footerState)}
      </div>
      <hr />
      <div className="bottom-section">
        {IS_DESKTOP ? (
          <Footer2
            footerState={footerState}
            store={store}
            propStyle={propStyle}
            isAlreadyTransformed={true}
          />
        ) : (
          <Footer1
            footerState={footerState}
            store={store}
            propStyle={propStyle}
            isAlreadyTransformed={true}
          />
        )}
      </div>
    </FooterMobileContainer>
  );
};

export default FooterDesktop;
