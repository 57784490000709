import styled from 'styled-components';
import { FOOTER_OPACITY } from '../../utils/common';

export const FooterContainer = styled.div`
  width: 100%;
  position: relative;
  &.bg_type_solid {
    background: ${(props) => props?.themeBackground?.backgroundColor};
  }
  color: ${(props) => props?.themeBackground?.body?.color};
`;

export const FooterDesktopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  & > .top-section {
    padding: 2.5rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  & .top-section.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
  }
  & .top-section.bg_type_image::after {
    content: '';
    background: ${(props) => props?.themeBackground?.backgroundColor};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${FOOTER_OPACITY};
    z-index: 1;
  }
  & > .top-section.bg_type_solid {
    background: ${(props) => props?.themeBackground?.backgroundColor};
  }
  & > .top-section .top-first-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 12;
  }
  & > .top-section .top-first-section p {
    z-index: 12;
    margin: 0;
    text-align: center;
    font-size: 0.75rem;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
  }
  & > .top-section .top-first-section .nav-links-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    z-index: 11;
  }
  & > .top-section .top-first-section .nav-links-container .nav-links {
    z-index: 12;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    font-weight: ${(props) => props?.themeBackground?.buttonSecondary?.fontWeight};
    font-family: ${(props) =>
      props?.themeBackground?.buttonSecondary?.fontFamily || 'Arial'};
    color: ${(props) => props?.themeBackground?.buttonSecondary?.color};
  }
  & > .top-section .top-first-section .nav-links-container .nav-links svg path {
    fill: currentColor;
  }
  &
    > .top-section
    .top-first-section
    .nav-links-container
    .nav-links
    .dropdown-container {
    background: ${(props) => props?.themeBackground?.backgroundColor};
    z-index: 12;
    top: 28px;
    width: 15.5rem;
  }
  & > .top-section .footer-description {
    z-index: 11;
    font-size: 1rem;
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    color: ${(props) => props?.themeBackground?.body?.color};
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    overflow-wrap: break-word;
    overflow-wrap: anywhere;
  }
  & > hr {
    max-width: 100%;
    background-color: ${(props) => props?.themeBackground?.borderColor};
    opacity: 0.6;
    margin: 0;
  }
  & > .bottom-section .footerDesktopContainer {
    padding: 2.5rem 4rem;
    background: ${(props) => props?.themeBackground?.backgroundColor} !important;
  }
`;

export const FooterMobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  & > .top-section {
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  & > .top-section.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
  }
  & > .top-section.bg_type_image::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${FOOTER_OPACITY};
    z-index: 1;
  }
  & > .top-section.bg_type_solid {
    background: ${(props) => props?.themeBackground?.backgroundColor};
  }
  & > .top-section .top-first-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    z-index: 10;
  }
  & > .top-section .top-first-section p {
    margin: 0;
    text-align: center;
    font-size: 0.75rem;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > .top-section .top-first-section .nav-links-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    z-index: 11;
  }
  & > .top-section .top-first-section .nav-links-container .nav-links {
    display: flex;
    flex-direction: column;
    z-index: 11;
  }

  .nav-links .links {
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    font-weight: ${(props) => props?.themeBackground?.buttonSecondary?.fontWeight};
    font-family: ${(props) =>
      props?.themeBackground?.buttonSecondary?.fontFamily || 'Arial'};
    color: ${(props) => props?.themeBackground?.buttonSecondary?.color};
  }
  &
    .top-section
    .top-first-section
    .nav-links-container
    .nav-links
    .dropdown-container
    div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 14;
  }
  & > .top-section .top-first-section .nav-links-container .nav-links svg path {
    fill: currentColor;
  }

  &
    > .top-section
    .top-first-section
    .nav-links-container
    .nav-links
    .dropdown-container {
    background: ${(props) => props?.themeBackground?.backgroundColor};
    z-index: 12;
    position: relative;
    top: 0;
    width: 100%;
  }
  & > .top-section .footer-description {
    z-index: 11;
    font-size: 1rem;
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    color: ${(props) => props?.themeBackground?.body?.color};
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    overflow-wrap: break-word;
    overflow-wrap: anywhere;
  }
  & > hr {
    max-width: 100%;
    background-color: ${(props) => props?.themeBackground?.borderColor};
    opacity: 0.6;
    margin: 0;
  }
  & > .bottom-section > div {
    padding: 0rem !important;
    background: ${(props) => props?.themeBackground?.backgroundColor} !important;
  }
`;
