import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { FooterContainer } from './Footer3.styled';

import FooterDesktop from './Components/FooterDesktop';
import { useSSRSelector } from '@/redux/ssrStore';

const Footer3 = (props) => {
  const [footerState, setFooterState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  useEffect(() => {
    setFooterState(transformWidgetProps(props?.config?.children));
  }, [props]);
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const [headerLinks, setHeaderLinks] = useState(
    transformWidgetProps(storeData?.theme?.components?.Header[0]?.config?.children)
  );
  return (
    <FooterContainer
      id="Footer3"
      propsBackground={footerState?.media?.background}
      themeBackground={propStyle}
    >
      <FooterDesktop
        store={storeData}
        footerState={footerState}
        setFooterState={setFooterState}
        propStyle={propStyle}
        headerLinks={headerLinks?.nav_links?.content}
        setHeaderLinks={setHeaderLinks}
      />
    </FooterContainer>
  );
};
export default Footer3;
